.sideNav {
  opacity: 1;
  background-color: white;
  width: 670px;
  height: 100vh;
  position: absolute;
  z-index: 40;
  padding-left: 2.5rem /* 40px */;
  padding-right: 2.5rem /* 40px */;
  padding-top: 1.25rem /* 20px */;
  padding-bottom: 1.25rem /* 20px */;
}

.toplinks {
  padding-bottom: 2.5rem /* 40px */;
  padding-top: 0.5rem /* 8px */;
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
  --tw-border-opacity: 1;
  border-color: rgb(130 130 130 / var(--tw-border-opacity));
  margin-bottom: 2rem /* 32px */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 400;
  font-size: 14px;
  color: black;
}

a > span {
  color: black;
}

a > span:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 700px) {
  .sideNav {
    width: 100%;
    padding-left: 1rem /* 40px */;
    padding-right: 1rem /* 40px */;
    padding-top: 0.5rem /* 20px */;
    padding-bottom: 0.5rem /* 20px */;
  }
}
