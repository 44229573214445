:root {
  --main-color: #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rs-input.rs-input-default.rs-input-border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rs-nav-item {
  color: var(--main-color);
}

.navbar-brand {
  margin-right: 0 !important;
}

body > iframe {
  display: none !important;
}

/* .rs-picker-menu {
  position: fixed !important;
} */

/* for the accordion dropdown */
/* .rs-icon{
  color: green !important;
} */

.swiper-button-next,
.swiper-button-prev {
  color: var(--main-color) !important;
}

.rs-nav-item.rs-nav-item-active {
  color: var(--main-color) !important;
}

/* for Input Number  */
.rs-input-group {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  outline: none !important;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input {
  background-color: transparent !important;
  outline: none !important;
}

/* For Nav */
.rs-nav-subtle .rs-nav-item.rs-nav-item-active:before {
  background-color: var(--main-color) !important;
}

/* FOR SIDE NAV */
.rs-sidenav-default,
.rs-sidenav-default .rs-dropdown-toggle,
.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: var(--main-bg-color) !important;
  color: #ffff !important;
}

/* FOR BOOTSTRAP CAROUSEL */
.carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid white !important;
  border-radius: 10px !important;
}

/* FOR SIDE NAV */
/* .rs-sidenav-default,
.rs-sidenav-default.active */
/* .rs-dropdown-toggle, */
/* .rs-sidenav-default  */
/* .rs-sidenav-item, 
.rs-sidenav-default 
.rs-sidenav-toggle-button.active  */
/* {
  border-left: 5px solid red !important;
} */

/* FOR SIDE NAV ACTIVE */
.rs-sidenav-default.rs-sidenav-collapse-in
  .rs-sidenav-item.rs-sidenav-item-active {
  border-left: 5px solid #ffffff !important;
}

/* FOR SIDE NAV ACTIVE */
.rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active {
  border-left: 5px solid #ffffff !important;
}

/* FOR DATEPICKER AND INPUTPICKER HEIGHT AROUND CONTAINER */
.rs-picker-toggle {
  height: 100% !important;
}

/* for pagination */
.rs-pagination-lg,
.rs-pagination-btn,
.rs-pagination-btn {
  padding: 0px !important;
  margin: 0px !important;
}

/* Rating default colo0r */
.rs-rate {
  color: #ffe234 !important;
}

/* .collapse:not(.show){
display: block !important;
} */

#sidebarMenu .nav-item:hover,
#sidebarMenu .nav-item:hover > .nav-link {
  background: #ffffff;
  color: #686868 !important;
  /* border-left: 5px solid #000000 !important; */
}

/* resize font size on inputplaceholder */
.rs-picker-input .rs-picker-toggle {
  font-size: 13px !important; /* adjust the size to your liking */
}

#sidebarMenu .nav-item.active {
  background: #ffffff;
  color: var(--main-color) !important;
  border-left: 5px solid #000000 !important;
}
#sidebarMenu .nav-item.active a {
  background: #ffffff;
  color: var(--main-color) !important;
}

/* for pagination width */
.rs-pagination-lg .rs-pagination-btn {
  min-width: 30px !important;
}

@media screen and (max-width: 300px) {
  .rs-drawer-body-close {
    margin-left: 9rem;
  }
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Mulish", "Poppins", sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}



@media screen and (max-width: 380px) {
  .rs-drawer-content{
    padding: 0 3rem;
  }

}

@media screen and (max-width: 356px) {
  .rs-drawer-content{
    padding: 0 3rem 0 6rem;
  }

}

@media screen and (max-width: 320px) {
  .rs-drawer-content{
    padding: 0 0rem 0 8rem;
  }

}


