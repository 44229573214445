.card {
  /* background: rgba(196, 196, 196, 0.25); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 calc(24.5% - 5px);
}

.card:hover {
  background: rgba(196, 196, 196, 0.25);
  box-shadow: 0px 4px 40px rgba(196, 196, 196, 0.25);
}
.card:hover .actions {
  display: block;
}

.card:hover .text {
  box-shadow: 0px 4px 40px rgba(196, 196, 196, 0.25);
}

.card .actions {
  display: none;
}

.actions {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.actions > span {
  display: block;
  background-color: white;
  padding: 5px 0;
  margin-bottom: 6px;
  cursor: pointer;
}
.actions > span > i {
  font-size: 1.5rem;
}

.img_wrap {
  height: 460px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.img_wrap img {
  /* max-width: 100%;
  max-height: 100%; */
  width: 100%;
  height: 100%;
  object-fit:cover;
  object-position: top;
}

.text {
  /* background-color: white; */
  /* padding: 15px; */
  width: 100%;
  text-align: left;
}

.text p {
  margin: 0;
  color: var(--main-color);
}

/* .text div {
  margin: 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .text div p {
  margin-left: 10px;
} */

.discount{
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
  font-size: 1.1rem;
  background-color: black;
  position: absolute;
  top:3%;
  left:5%;
  font-weight: 700;
}

.rating {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.Notes{
  text-align: center;
  font-weight: 600;
font-size: 1.6rem;
line-height: 18px;
  color: #000000;
  margin-top: 1.4rem;
  margin-bottom: 3.6rem;
}

.rating p {
  margin: 0;
  padding: 0;
  margin-left: 1.5rem;
  font-weight: 700;
}

@media screen and (max-width: 940px) {
  .card {
    flex: 0 0 calc(32% - 5px);
  }
}

@media screen and (max-width: 729px) {
  .card {
    flex: 0 0 calc(49% - 2px);
  }
}

@media screen and (max-width: 500px) {
  /* .img_wrap {
    height: 200px;
  } */
  .text {
   font-size: 1.2rem;
  }
}




@media screen and (max-width: 450px) {
  .img_wrap {
    height: 260px;
  }
}

@media screen and (max-width: 319px) {
  .card {
    flex: 0 0 calc(99% - 0px);
  }

  .img_wrap {
    height: 360px;
  }
}




