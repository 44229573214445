.MainWrapper{
  background-color: #928c8c;
  color: var(--main-color);
}


.MainContent {
    padding-left: 6.2rem;
    padding-right:4.2rem;
    padding-top: 5.3rem;
    /* background-color: #a8a6a6; */
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.2px;
    font-size: 1.3rem;
    flex-wrap: wrap;
  }

  .Add a {
    color: inherit !important;
  }

  .Add a:hover{
    text-decoration: none !important;
  }

  .ContentText {
    font-weight: 400;
    line-height: 2.5rem;
    margin-right: 6rem;
    margin-bottom: 2rem;
  }


  .ContentSocial{
    margin-right: 8rem;
  }

  Link p{
    color: var(--main-color); 
  }
  
  .Lg {
    display: flex;
    align-items: center;
    gap:1.8rem;
    margin-bottom: 1rem;
    cursor: pointer;
    color: var(--main-color) !important;
    text-decoration: none;
  }

  .Lg:hover,.Lg:visited {
    text-decoration: none; 
    color: var(--main-color); 
  }

  .ContentSub{
    margin-right: 20rem;
  }

  @media screen and (min-width: 1500px)  {
    .MainContent{
      width: 1450px; 
       margin: auto;
    }
    
  
  }
  

  
  @media screen and (max-width: 1260px) {
    .ContentSub{
      margin-right: 12rem;
    }
    .ContentSocial{
      margin-right: 6rem;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .ContentSub{
      margin-right: 5rem;
    }
    .ContentSocial{
      margin-right: 1rem;
    }
    .ContentSocial{
      margin-right: 4rem;
    }
    .Logo {
      margin-right: 4rem;
    }
  }

  
  @media screen and (max-width: 790px) {
    .MainContent {
      padding-left: 6.2rem;
      padding-right:4.2rem;
    } 
  } 


    @media screen and (max-width: 710px) {
      .Logo{
        display: none;
      }
       .MainContent {
        /* flex-direction: column; */
        font-size: 1.5rem;
      }

      .ContentSub{
        margin-right: 0rem;
        margin-bottom: 3rem;
      }
      .ContentSocial{
        margin-right: 0rem;
        margin-bottom: 8rem;

      }
      .ContentSocial{
        margin-right: 0rem;

      }
      .Logo {
        margin-right: 0rem;
      } 
  }


