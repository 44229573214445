.banner {
  width: 100%;
  background-color: #ffff;
  padding-bottom: 14.5rem;
}

.banner_main {
  display: flex;
  /* height: 79.1rem; */
  width: 100%;
  height: 79.1rem;
  overflow: hidden;
  position: relative;
}

.banner_main_first,
.banner_main_second {
  position: relative;
  overflow: hidden;
}

.banner_main_first {
  background-image: url("../../../assets/images/pattern.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
}

.banner_main_first_content {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(97, 71, 24, 0.85),
    rgba(97, 71, 24, 0.85)
  );
  /* background-color: rgba(161, 74, 66, 0.85); */
  padding-top: 18.8rem;
  padding-left: 8.3rem;
  color: #ffff;
  position: relative;
}

.scroll{
  font-size:400;
  color:#ffff;
  position: absolute;
  top:80%;
  left:90%;
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  align-items: center;
  justify-content: center;
  gap:1rem;
  cursor:pointer;
}


.best_seller {
  padding: 4rem;
}

.banner_main_first_content p:first-child {
  color: #ce6257;
  /* border: 1.09px solid #EB5757; */
  width: 19.2rem;
  /* padding:4.34px, 8.69px, 4.34px, 8.69px; */
  border-radius: 3.5rem;
  background-color: #ffff;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3.4rem;
}

.banner_main_first_content h1 {
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 7.8rem;
  margin-bottom: 3.4rem;
  font-family: "theSeasons", sans-serif;
}

.banner_main_first_content p:nth-last-of-type(2) {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #f2f2f2;
  width: 40rem;
  margin-bottom: 3.4rem;
}

.banner_main_first_content p:last-child {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30rem;
  cursor: pointer;
}

.banner_main_first_content p:last-child::after {
  content: "";
  width: 12rem;
  height: 0.1rem;
  background-color: #ffff;
  display: block;
  margin-top: 0.5rem;
}

.best_seller {
  width: 100%;
}
.banner_main_second {
  background-size: cover;
  object-fit: cover;
  display: block;
  width: 50%;
}

.best_seller p:first-child {
  font-size: 4.8rem;
  /* color: #4F4F4F; */
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
  /* font-family: "theSeasons", sans-serif; */
  font-family: "Berkshire Swash", serif;
}

.best_seller_content {
  margin-top: 2rem;
  display: flex;
  gap: 2.7rem;
}

.best_seller_content_first {
  width: 50%;
  height: 62rem;
}

.best_seller_content_first_heading,
.best_seller_content_first_heading_long {
  /* padding-left: 1.3rem; */
  color: #4f4f4f;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}

.best_seller_content_first_heading::after {
  content: "";
  width: 100%;
  height: 0.1rem;
  background-color: #828282 !important;
  display: block;
  margin-top: 0.5rem;
}

.best_seller_content_first_heading_long::after {
  content: "";
  width: 17rem;
  height: 0.1rem;
  background-color: #828282 !important;
  display: block;
  margin-top: 0.5rem;
}

.best_seller_content_first_text {
  /* padding-left: 1.3rem; */
  font-size: 1.4rem;
  font-weight: 300;
}

.best_seller_content_second {
  width: 50%;
  display: flex;
  gap: 2.7rem;
}

.best_seller_content_second_first {
  /* width: 31.5rem; */
  width: 50%;
}

.best_seller_content_second_first_1 {
  /* height: 26.4rem; */
  width: inherit;
  margin-bottom: 5.5rem;
}

.best_seller_image {
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  position: relative;
}

.background_wrapper {
  position: relative;
}

.best_seller_image_change {
  background-image: url("https://trybz.fra1.cdn.digitaloceanspaces.com/landing/luxury/trybz-ca-luxury-dress.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 37rem;
  margin-bottom: 1rem;
}

.best_Seller_cover {
  position: absolute;
  bottom: 0;
  background-color: #f2f2f2;
  opacity: 0.7;
  height: 33%;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  display: flex;
  gap: 2rem;
}

.best_Seller_cover h6 {
  color: #4f4f4f;
  font-size: 2rem;
  font-weight: 600;
}

.best_Seller_cover button {
  margin-top: auto;
}

.best_seller_image_main {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.best_seller_image_end_1 {
  height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.best_seller_image_last {
  height: 245px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.best_seller_content_second_first_2 {
  /* height: 26.4rem; */
  width: 100%;
}

.best_seller_content_second_second {
  height: 67.5rem;
  width: auto;
}

.best_seller_content_first_button {
  margin-left: 1.3rem;
  outline: none;
  /* border: 1px solid #828282; */
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  margin-top: 2.4rem;
  font-size: 2rem;
  font-weight: 400;
}

.best_seller_content_first_button:hover {
  border: 1px solid #828282;
  background-color: #ffff;
}

.button_main {
  padding: 1rem 18rem;
}

.button_sub {
  padding: 1rem 1.6rem;
  width: 90%;
}

.best_seller_content_first_button img {
  margin-left: 0.7rem;
}

.best_seller_content_first_heading_tranparent,
.best_seller_content_first_heading_long {
  color: black;
  font-size: 2rem;
  font-weight: 600;
}

.best_seller_content_first_heading_tranparent::after {
  content: "";
  width: 100%;
  height: 0.1rem;
  background-color: #828282 !important;
  display: block;
  margin-top: 0.5rem;
}

.best_seller_content_first_button_transparent {
  outline: none;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

.best_seller_content_first_button_transparent:hover {
  border: 1px solid #828282;
  background-color: #ffff;
}

.button_background_transparent {
  text-align: center;
  width: 100%;
  padding: 1.1rem 2.1rem;
  margin-top: auto;
}

.recommendation {
  margin-top: 5rem !important;
  width: 100%;
}

.recommendation h1 {
  font-family: "Berkshire Swash", serif;
  color: rgba(51, 51, 51, 1);
}

.wrapper {
  margin-top: 2.4rem;
  /* margin-bottom: 14.5rem; */
}

.blog {
  height: 68rem;
  background-color: #e0e0e0;
  width: 100% !important;
  padding: 0 5rem;
  position: relative;
}

.blog_wrapper {
  display: flex;
  gap: 1rem;
}

.blog h2 {
  color: #f2f2f2;
  font-size: 4.8rem;
  padding-top: 8.2rem;
  margin-bottom: 3.9rem;
}

.blog p {
  margin-bottom: 7.2rem;
  width: 95%;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: 400;
}

.blog_wrapper_content {
  border-radius: 1.6rem;
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: #f8f9fa;
  padding: 3rem 3.6rem;
  width: 50%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
}

.content_img {
  margin-bottom: 3.2rem;
  width: 45rem;
  height: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.blog_wrapper_content h6 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
  margin-bottom: 1.8rem;
}

.blog_wrapper_content p {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  margin-bottom: 1.8rem;
}

.blog_wrapper_content :last-child {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #e11011;
  object-fit: cover;
}

.info {
  max-width: 100%;
  margin: auto;
  margin-top: 40rem !important;
  margin-bottom: 8rem;
}

.info_wrapper {
  color: rgba(51, 51, 51, 1);
}

.info_wrapper p:last-child {
  margin-bottom: 0rem;
}

.headSet {
  margin-top: -2rem;
}

@media screen and (min-width: 1450px) {
  .best_seller {
    padding: 0rem;
  }
}

@media screen and (max-width: 1300px) {
  .best_seller {
    padding: 3rem;
  }

  /* .best_seller_content_second_first {
    width: auto;
  } */
}

@media screen and (max-width: 1125px) {
  .best_seller {
    padding: 0rem;
  }

  .best_seller_content_second_first {
    width: 100%;
  }

  .best_seller_content {
    flex-direction: column;
    padding: 0 12rem;
  }

  .best_seller_content_first,
  .best_seller_content_second {
    width: 100%;
    height: auto;
  }

  .best_seller_content_second {
    margin-top: 5rem;
  }

  .best_seller_content_second_second {
    width: 100%;
  }
}

@media screen and (max-width: 980px) {
  .banner_main_first_content {
    padding-left: 3rem;
  }

  .banner_main_first_content p:nth-last-of-type(2) {
    width: 90%;
  }
}

@media screen and (max-width: 826px) {
  .best_seller_content {
    padding: 0 4rem;
  }

  .blog_wrapper {
    flex-direction: column;
  }

  .blog_wrapper_content {
    width: 100%;
  }

  .blog {
    height: auto;
  }

  .info {
    margin-top: 5rem !important;
  }
}

@media screen and (max-width: 630px) {
  .banner_main {
    flex-direction: column;
    height: auto;
  }

  .banner_main_first,
  .banner_main_second {
    width: 100%;
  }

  .banner_main_first {
    height: 50rem;
  }

  .banner_main_second {
    height: 50rem;
  }

  .best_seller_image_main {
    object-position: top;
  }

  .banner_main_first_content {
    padding-top: 5rem;
  }

  .best_seller_content {
    padding: 0 1rem;
  }

  .button_main {
    padding: 1rem 10rem;
  }
  .content_img {
    margin-bottom: 3.2rem;
    width: 25rem;
    height: 25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .scroll{
    top:90%;
    left:50%;
  }
}

@media screen and (max-width: 560px) {
  .best_seller_content_second,
  .best_seller_content_second_first {
    flex-direction: column;
    width: 100%;
  }

  .best_seller_content_second_second {
    height: auto;
  }

  .best_Seller_cover {
    width: 100%;
  }

  .button_sub {
    width: 100%;
  }

  .best_seller_content_first_button {
    margin-left: 0;
  }

  .best_seller_content_second_first_diff {
    margin-bottom: 1.5rem;
  }
  .best_Seller_cover p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 470px) {
  .blog {
    padding: 0 2rem;
  }

  .button_sub {
    padding: 1rem 1rem;
    width: 100%;
  }

  .best_seller_content_first_button {
    margin-left: 0;
  }

  .button_main {
    width: 100%;
    padding: 1rem 5rem;
  }

  .blog_wrapper_content {
    padding: 2rem;
  }
}
