@font-face {
  font-family: "theSeasons";
  src: url("../fonts/Fontspring-DEMO-theseasons-bd.otf") format("woff2"),
    url("../fonts//Fontspring-DEMO-theseasons-bdit.otf") format("woff"),
    url("../fonts/Fontspring-DEMO-theseasons-reg.otf") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none !important;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  z-index: 30;
}

.overlay.show {
  position: relative;
}
.sideNav {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 40;
  width: 100%;
  height: 100%;
}

.home {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  font-family: "poppins", sans-serif;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    /* url("/public/trybz-home.png"); */
    url("https://trybz.fra1.cdn.digitaloceanspaces.com/landing/trybz-ca-landing-page.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.top {
  display: flex;
  justify-items: center;
  gap: 160px;
  align-items: center;
  padding-top: 135px;
}
.top-first {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 20px;
}
.top-second {
  display: flex;
  justify-items: center;
  gap: 20px;
  align-items: center;
}
.input {
  width: 100%;
  height: 6rem;
  border-radius: 12px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(130 130 130 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 1.3rem;
  line-height: 1.25rem;
  margin-top: 15px;
  margin-left: 20px;
}

.left {
  padding-left: 80px;
  padding-right: 80px;
}

.left_div {
  margin-top: 22rem;
}

.shop {
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: white;
  text-decoration-line: underline;
  text-underline-offset: 18px;
  padding-left: 29px;
}

.deals {
  font-weight: 400;
  padding-top: 16rem;
  font-size: 1.6rem;
  line-height: 1.5rem;

  --tw-text-opacity: 1;
  color: white;
  padding-left: 22px;
}

.right {
  display: flex;
  overflow-x: hidden;
  width: 100%;

  position: relative;
}

.subcontext {
  position: absolute;
  top: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.subcontext_first {
  width: 100%;
  margin-left: 2rem;
  --tw-text-opacity: 1;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  margin-bottom: 15rem;
}

.subcontext_first > h4 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
}

.subcontext_first > h6 {
  text-decoration-line: underline;
  text-underline-offset: 13px;
}

.subcontext > h6 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.hovercontent {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hovercontent_first-div {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 13rem;
}
.hovercontent_links button {
  background-color: transparent !important;
}
.hovercontent_links {
  font-size: 1.5rem;
  line-height: 1rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hovercontent_links:hover {
  font-size: 2rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 400;
  text-decoration: none;
}

.hovercontent_title {
  font-size: 30px;
  line-height: 60px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.7rem;
  padding-left: 15px;
  padding-right: 15px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}




@media (max-width: 440px) {
  .item{
    margin-top: 15rem;
  }
}

@media (max-width: 768px) {
  .home {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("https://trybz.fra1.cdn.digitaloceanspaces.com/landing/trybz-ca-landing-page-mobile.jpg");
    height: 100vh;
    background-position: center;
  }

  .left_div {
    display: none;
  }
  .left {
    margin: auto;
    z-index: 10;
  }
  .hovercontent_links > button {
    background-color: transparent !important;
  }
  .top {
    display: flex;
    justify-items: center;
    gap: 70px;
    align-items: center;
    padding-top: 40px;
    z-index: 10;
  }

  .home {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 100vh;
  }
  .right {
    width: 100vw !important;
    margin-top: -100vh;
  }
  .left {
    padding-top: 0;
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .subcontext {
    position: absolute;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 2rem;
  }

  .subcontext_first {
    width: 100%;
    margin-left: 0rem;
    --tw-text-opacity: 1;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    /* margin-bottom: 30rem; */
    padding: 0 1rem;
  }

  .subcontext_first > h4 {
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
  }

  .subcontext_first > h6 {
    font-size: 1.25rem;
    line-height: 1rem;
    font-weight: 600;
    text-decoration-line: underline;
    text-underline-offset: 13px;
  }

  .hovercontent {
    position: absolute;
    width: 50vw;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .hovercontent_first-div {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    padding-top: 20rem;
  }

  .hovercontent_links {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .hovercontent_links:hover {
    font-size: 2rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 400;
    text-decoration: none;
  }

  .hovercontent_title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 0.7rem;
    padding-left: 15px;
    padding-right: 15px;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

/* @media (min-width: 1280px) {
  .left {
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .left_first-div {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .shop {
    font-weight: 600;
    font-size: 2rem;
  }

  .deals {
    font-weight: 400;
    padding-top: 15rem;
    font-size: 1.7rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  }

  .right {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    position: relative;
  }

  .subcontext {
    position: absolute;
    top: 0px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 80px;
  }

  .subcontext_first {
    width: 100%;
    margin-left: 3rem;
    --tw-text-opacity: 1;

    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .subcontext_first > h4 {
    font-size: 35px;
    line-height: 50px;
    font-weight: 600;
  }

  .subcontext > h6 {
    font-size: 1.2rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
  .subcontext_first > h6 {
    text-underline-offset: 10px;
  }
  .hovercontent {
    gap: 8rem;
  }

  .hovercontent_first-div {
    gap: 4rem;
    padding-top: 5rem;
    padding-left: 40px;
    padding-right: 40px;
  }

  .hovercontent_links {
    font-size: 1.2rem;
  }
  .hovercontent_links:hover {
    font-size: 1.4rem;
  }

  .hovercontent_title {
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
} */

@media (min-width: 1536px) {
  .input {
    width: 100%;
    height: 7rem;
    border-radius: 12px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(130 130 130 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-size: 1.3rem;
    line-height: 1.25rem;
    margin-top: 15px;
    margin-left: 20px;
  }

  .left {
    padding-left: 80px;
    padding-right: 80px;
    height: 100vh;
  }

  .left_div {
    margin-top: 37rem;
  }

  .shop {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: white;
    text-decoration-line: underline;
    text-underline-offset: 18px;
    padding-left: 29px;
  }

  .deals {
    font-weight: 400;
    padding-top: 16rem;
    font-size: 1.8rem;
    line-height: 1.5rem;

    --tw-text-opacity: 1;
    color: white;
    padding-left: 22px;
  }

  .subcontext {
    position: absolute;
    top: 0px;
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .subcontext_first {
    width: 100%;
    margin-left: 2rem;
    --tw-text-opacity: 1;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    margin-bottom: 10rem;
  }

  .subcontext_first > h4 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 600;
  }

  .subcontext_first > h6 {
    text-decoration-line: underline;
    text-underline-offset: 13px;
  }

  .subcontext > h6 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }

}
