.wrapper {
  /* max-width: 129.6rem; */
  /* margin: auto; */
  /* padding: 0 10rem; */
}


.dropdown a {
  color: black !important;
  font-size: 1.4rem;
  font-weight: 500;

  padding-top: 10px;
}
.header {
  height: 6.8rem;
  background-color: #eae8e8;
  display: flex;
  justify-content: space-between;
  min-height: auto;
}

.header_tabs {
  display: flex;
  height: 100%;
  align-items: center !important;
}

.header_tabs_basic,
.header_tabs_luxury {
  width: 14.5rem;
  height: inherit;
  font-size: 2rem;
  font-weight: 600;
  padding-left: 2.1rem;
  color: #333333;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.userProfile div span {
  cursor: pointer;
}

.userProfileLinks {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* justify-content: left;
  align-items: left; */
  padding: 0px 120px 0px 20px;
  gap: 8px;
  padding-bottom: 2rem;
}

.userProfile div p {
  cursor: pointer;
}

.header_logo_div{
display: flex;
}

.header_logo{
  width: 10%;
}

.header_logo1 {
  width: 45%;
}

.header_logo2{
  height: inherit;
  width: 70%;
}

.header_info {
  padding-right: 5.2rem;
  display: flex;
  gap: 1.8rem;
  align-items: center;
  cursor: pointer;
}

.header_info img {
  width: 20px;
  height: 20px;
}


.header_nav ul {
  list-style-type: none;
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-evenly;
  /* margin-bottom: 1.5rem; */
}

ul li {
  display: inline;
  margin-right: 1.6rem;
  font-size: 1.4rem;
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.drawer h6 {
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 2rem;
  color: #000000;
}

.drawer_trending {
  margin-top: 6rem;
  font-weight: 600;
}

.drawer_trending p {
  margin-bottom: 2rem;
  color: #000000;
}

.drawer_trending button {
  outline: none;
  /* border: 1px solid #828282; */
  /* border-radius: 5px; */
  background-color: transparent;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0.6rem 1rem;
}

.drawer_trending_buttons {
  display: grid;
  grid-template-columns: repeat(4, minmax(1rem, 1fr));
  gap: 2rem;
}

.drawer_trending_contents {
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  gap: 1rem;
}

@media screen and (min-width: 1450px) {
  .wrapper {
    /* width: 100%;
    margin: auto; */

    width: 1450px;
    margin: auto;
  }

  .header_div{
    background-color: #eae8e8;
  }
}




@media screen and (max-width: 720px) {
  .header_info {
    padding-right: 2rem;
  }

  .header_logo {
    width: 13%;
  }
}

@media screen and (max-width: 630px) {
  .wrapper {
    margin-top: 6.6rem;
  }

  .header_nav,
  .header_info,
  .header_logo,.header_logo_div {
    display: none;
  }

  .drawer_trending_buttons {
    grid-template-columns: repeat(2, minmax(1rem, 1fr));
    gap: 1rem;
    margin-bottom: 3rem;
  }

  .header {
    margin-top: -1.6rem;
  }

  .header_tabs {
  width: 100%;
  }
  
  .header_tabs_basic,
  .header_tabs_luxury {
    width: 50%;
  }

}


@media screen and (max-width: 550px) {

  /* .wrapper {
    margin-top: 8.2rem;
  } */

  .header {
    margin-top: -1rem;
  }

}


@media screen and (max-width: 497px) {

  /* .wrapper {
    margin-top: 7.5rem;
  } */

}
