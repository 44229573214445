body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #ffff;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-input.rs-input-default.rs-input-border {
  background-color: transparent !important;
  box-shadow: none !important;
}

html {
  font-size: 62.5%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
