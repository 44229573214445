.banner {
  width: 100%;
  background-color: #ffff;
  padding-bottom: 14.5rem;
}

.banner_main {
  display: flex;
  min-height: 79.1rem;
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* .banner_main_first,
.banner_main_second {
  position: relative;
  overflow: hidden;
} */

.banner_main_second {
  height: 79.1rem;
}

.banner_main_first {
  background-image: url("../../../assets/images/pattern.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
}

.best_seller {
  padding: 4rem;
}

.banner_main_first_content {
  width: 100%;
  height: 100%;
  background: linear-gradient(to_right, rgba(161, 74, 66, 0.85));
  background-color: rgba(161, 74, 66, 0.85);
  padding-top: 18.8rem;
  padding-left: 8.3rem;
  color: #ffff;
  position: relative;
}

.scroll{
  font-size:400;
  color:#ffff;
  position: absolute;
  top:80%;
  left:45%;
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  align-items: center;
  justify-content: center;
  gap:1rem;
  cursor:pointer;
}

.banner_main_first_content p:first-child {
  color: #ce6257;
  width: 19.2rem;
  border-radius: 3.5rem;
  background-color: #ffff;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3.4rem;
}

.banner_main_first_content h1 {
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 7.8rem;
  margin-bottom: 3.4rem;
  font-family: "theSeasons", sans-serif;
}

.banner_main_first_content p:nth-last-of-type(2) {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #f2f2f2;
  width: 40rem;
  margin-bottom: 3.4rem;
}

.banner_main_first_content p:last-child {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30rem;
  cursor: pointer;
}

.banner_main_first_content p:last-child::after {
  content: "";
  width: 12rem;
  height: 0.1rem;
  background-color: #ffff;
  display: block;
  margin-top: 0.5rem;
}

.best_seller {
  width: 100%;
}

.banner_main_second {
  background-size: cover;
  object-fit: cover;
  display: block;
  width: 50%;
}

.best_seller p:first-child {
  font-size: 4.8rem;
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
  font-family: "Berkshire Swash", serif;
}

.best_seller_content {
  display: flex;
  gap: 2.7rem;
  /* background-color: #e11011; */
  margin-bottom: 25rem;
  height: 58rem;
  min-height: 58rem;
}

.best_seller_content_first {
  width: 50%;
}

.best_seller_image_main {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.button_main {
  text-align: center;
  width: 100%;
  padding: 1.7rem 2.6rem;
}

.best_seller_content_first_heading,
.best_seller_content_first_heading_long {
  color: #4f4f4f;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.8rem;
  margin-bottom: 3rem;
}

.best_seller_content_first_heading::after {
  content: "";
  width: 100%;
  height: 0.1rem;
  background-color: #828282 !important;
  display: block;
  margin-top: 0.5rem;
}

.best_seller_content_first_heading_long::after {
  content: "";
  width: 17rem;
  height: 0.1rem;
  background-color: #828282 !important;
  display: block;
  margin-top: 0.5rem;
}

.best_seller_content_first_text {
  font-size: 1.4rem;
  font-weight: 300;
}

.best_seller_content_first_button {
  outline: none;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

.best_seller_content_first_button:hover {
  border: 1px solid #828282;
  background-color: #ffff;
}

.best_seller_content_second {
  width: 50%;
  display: flex;
  gap: 2.7rem;
}

.best_seller_content_second_first {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.best_seller_image_1 {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  object-position: top;
}

.best_seller_content_second_2 {
  width: 50%;
}

.best_seller_image_3 {
  height: 58rem;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.best_seller_content_second_first_2 {
  height: 35rem;
  position: relative;
}

.best_seller_content_second_background {
  background-image: url("https://trybz.fra1.cdn.digitaloceanspaces.com/landing/everyday/trybz-ca-everyday-bou-and-kimono.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 35rem;
}

.best_Seller_cover {
  position: absolute;
  bottom: 0%;
  background-color: #f2f2f2;
  opacity: 0.7;
  height: 48%;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}

.button_background {
  text-align: center;
  width: 100%;
  padding: 1.7rem 2.6rem;
  margin-top: auto;
}

.best_seller_content_first_heading_tranparent,
.best_seller_content_first_heading_long {
  color: black;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;
}

.best_seller_content_first_heading_tranparent::after {
  content: "";
  width: 100%;
  height: 0.1rem;
  background-color: #828282 !important;
  display: block;
  margin-top: 0.5rem;
}

.best_seller_content_first_button_transparent {
  outline: none;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

.best_seller_content_first_button_transparent:hover {
  border: 1px solid #828282;
  background-color: #ffff;
}

.button_background_transparent {
  text-align: center;
  width: 100%;
  padding: 1.1rem 2.1rem;
  margin-top: auto;
}

/* .best_seller_content_second {
  width: 50%;
  display: flex;
  gap: 1rem;
}

.best_seller_content_second_first {
  width: 31.5rem;
  height: 70rem;
}

.best_seller_content_second_first_1 {
  width: inherit;
  margin-bottom: 1rem;
}

.best_seller_image{
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.best_seller_image_end_1{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.best_seller_image_last{
  height: 245px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.best_seller_content_second_first_2 {
  width: inherit;
  background-color: green;
}

.best_seller_content_second_second {
  height: 67.5rem;
  width: auto;
}



.button_sub {
  padding: 1rem 1.6rem;
  width: 90%;
}

.best_seller_content_first_button img {
  margin-left: 0.7rem;
} */

.recommendation {
  width: 100%;
}

.recommendation h1 {
  font-family: "Berkshire Swash", serif;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 3rem !important;
}

.blog {
  height: 68rem;
  background-color: #e0e0e0;
  width: 100% !important;
  padding: 0 5rem;
  position: relative;
}

.blog_wrapper {
  display: flex;
  gap: 1rem;
}

.blog h2 {
  color: #f2f2f2;
  font-size: 4.8rem;
  padding-top: 8.2rem;
  margin-bottom: 3.9rem;
}

.blog p {
  margin-bottom: 7.2rem;
  width: 95%;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: 400;
}

.blog_wrapper_content {
  border-radius: 1.6rem;
  background-color: #f8f9fa;
  padding: 3rem 3.6rem;
  width: 50%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
}

.content_img {
  margin-bottom: 3.2rem;
  width: 45rem;
  height: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.blog_wrapper_content h6 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
  margin-bottom: 1.8rem;
}

.blog_wrapper_content p {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  margin-bottom: 1.8rem;
}

.blog_wrapper_content :last-child {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #e11011;
  object-fit: cover;
}

.headSet {
  margin-top: -2rem;
}

@media screen and (min-width: 1450px) {
  .best_seller {
    padding: 0rem;
  }
}

@media screen and (max-width: 980px) {
  .banner_main_first_content {
    padding-left: 3rem;
  }

  .best_seller_content_first {
    margin-bottom: 12rem;
  }

  .banner_main_first_content p:nth-last-of-type(2) {
    width: 90%;
  }

  .best_Seller_cover {
    width: 100%;
  }

  .best_seller_content {
    display: flex;
    gap: 1.5rem;
    /* background-color: #e11011; */
    margin-bottom: 25rem;
    /* min-height: 160rem; */
    margin-bottom: 140rem;
  }

  .best_seller {
    padding: 0rem;
  }

  .best_seller_content {
    flex-direction: column;
    padding: 0 6rem;
  }

  .best_seller_content_first,
  .best_seller_content_second {
    width: 100%;
  }

  .best_seller_content_second {
    margin-top: 5rem;
  }

  .best_seller_content_second_second {
    width: 100%;
  }
}

@media screen and (max-width: 826px) {
  /* .best_seller_content {
    padding: 0 4rem;
  } */

  .blog_wrapper {
    flex-direction: column;
  }

  .blog_wrapper_content {
    width: 100%;
  }

  .blog {
    height: auto;
  }

  .info {
    margin-top: 5rem !important;
  }

  .banner_main {
    height: 60rem;
  }

  .best_seller_content {
    flex-direction: column;
    padding: 0 1rem;
    /* min-height: 170rem; */
  }
  .wrapper {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 630px) {
  .banner_main {
    flex-direction: column;
    height: auto;
  }

  .banner_main_first,
  .banner_main_second {
    width: 100%;
  }

  .banner_main_first {
    height: 50rem;
  }

  .banner_main_first_content {
    padding-top: 5rem;
  }

  .best_seller_content {
    padding: 0 1rem;
  }

  .content_img {
    margin-bottom: 3.2rem;
    width: 25rem;
    height: 25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .best_seller_content_first {
    margin-bottom: 15rem;
  }

  .best_seller_content {
    /* min-height: 150rem; */
  }

  .banner_main_second {
    height: 40rem !important;
  }

  .best_seller_image_main {
    object-position: left top;
  }
  .wrapper {
    padding: 0 1rem;
  }

  .scroll{
    font-size:400;
    color:#ffff;
    position: absolute;
    top:50%;
    left:50%;
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    align-items: center;
    justify-content: center;
    gap:1rem;
    cursor:pointer;
  }
  
}

@media screen and (max-width: 440px) {
  .best_seller_content_second {
    flex-direction: column;
  }
  .best_seller_content_second_first,
  .best_seller_content_second_2 {
    width: 100%;
  }

  .best_seller_content {
    margin-bottom: 175rem;
  }
  .best_seller_image_main {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;
  }

  .wrapper {
    padding: 0 1rem;
  }
}
