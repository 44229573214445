.Wrapper{
    background-color: #ffff;
    /* max-width: 129.6rem; 
    margin: auto; */
}

.section_Banner{
    width: 100%;
    background-color: rgba(224, 224, 224, 1);

}

.info {
    margin: auto;
    margin-top: 40rem !important;
  }
  
  .info_wrapper {
    color: rgba(51, 51, 51, 1);
  }
  
  .info_wrapper p:last-child {
    margin-bottom: 0rem;
  }



@media screen and (min-width: 1450px) {
    .Wrapper,.info {
      width: 1450px;
      margin: auto;
    }
}


@media screen and (max-width: 450px) {
  .info {
    margin-top: 10rem !important;
  }
}

