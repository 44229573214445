.Nav_Text{
    text-decoration:none !important;
}



.Container_search{
    background-color: transparent;
    border: none;
    border-bottom: 0.4px solid rgba(0, 0, 0, 0.5);
    outline: none;
    font-size: 1.6rem;
    padding: 0;
    width:100%;
    margin-right: 1rem;
}

.Profile_info{
    padding:2rem;
    font-size: 1.5rem;
    display: none;
}

.ActiveNav{
    position: absolute;
    top: 98%;
    z-index: 999999;
    border-bottom: 2px solid #000000;
    width:2.5rem;
    text-align: center;
}


.CenteredDiv{
    position: absolute;
    top: 100%;
    background-color:#FFFFFF;
    width:100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.logo{
    max-width: 8.5%;
}

.Patner{
    margin:18px;
    margin-bottom:2rem;
    cursor:pointer;
    font-weight: bold;
    font-size: 1.4rem;
}


.Mobile_panel{
    max-height:30rem;
    overflow-y: scroll !important;
}

.containerNav{
    display: flex;
    justify-content: space-between;
}


.DivHeader{
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.2px;
    color: #000000;
    margin-top: 5.3rem;
    margin-bottom: 2.4rem;
}


.SearchCmp{
    font-size: 1.5rem !important;
}

.popUp{
    display: none;
}

.DivTextMobile{
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #686868;
    margin-bottom: 2rem;
}

.DivText{
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #686868;
    margin-bottom: 2rem;
}

.AuthDiv{
    width: 17rem;
    background: #000000;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    text-align: center;
    padding: 1.5rem 1.6rem;
    cursor: pointer;
}

.AuthAction{
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 18px;
    color: #FFFFFF;
}

.profileImage{
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top{
    width: 51px;
height: 40px;
background: #FFFFFF;
border: 1px solid #000000;
border-radius: 5px;
}

.top:hover{
    background-color:lightgray;
}

.Notes{
    text-align: center;
    font-weight: 600;
  font-size: 1.6rem;
  line-height: 18px;
    color: #000000;
    margin-top: 1.4rem;
    margin-bottom: 3.6rem;
  }

.Sub_nav{
    display: none;
}

/* 990 */

@media screen and (max-width: 630px) {

    .Main_nav{
        display: none;
    }

    .Sub_nav{
        display: block;
    }

    .Mobile_panel {
        font-weight: 600;
        margin-bottom: 4rem;
      }

    .logo{
        max-width: 15%;
    }

    .containerNav {
      display: flex;
      flex-direction: row;
    }

    .SearchCmp{
        display: none;
    }
  }




  @media screen and (max-width: 550px) {

    .logo{
        max-width: 25%;
    }
  }
