.banner_wrapper{
  background-color: rgba(224, 224, 224, 1);
  padding-top: 4rem;
}

.MainWrapper {
  width: 100%;
  margin: auto;
  padding: 2.8rem 8rem 0 7rem;
  height: 35rem;
  display: flex;
  /* gap: 15.4rem; */
  justify-content: space-between;
}

.MainWrapper_content {
  width: 50%;
}

.Input_container i {
top:25%;
left:80%;
}

.Input_container{
  width:34.4rem;
  position: relative;
}

.MainWrapper_content h1 {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 4rem;
  width: 28rem;
  line-height: 4.8rem;
  margin-bottom: 2.2rem;
}

.MainWrapper_content input {
  height: 5.7rem;
  width: 100%;
  border-radius: 1.4rem;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 2.2rem;
  border: none;
  padding-left: 2.3rem;
  outline: none;
}

.MainWrapper_content input::placeholder {
  font-size: 1.25rem;
}

.MainWrapper_content p {
  color: rgba(0, 0, 0, 1);
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 3.57rem;
}

.MainWrapper_content_2 {
  width: 30%;
  color: rgba(51, 51, 51, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MainWrapper_content_links {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 500;
}

.MainWrapper_content_links:first-child p {
  margin-bottom: 2.8rem;
}

@media screen and (min-width: 1450px) {
  .MainWrapper {
        width: 1450px;
        margin: auto;
        padding: 0;
      }
}


@media screen and (max-width: 783px) {
  .MainWrapper {
    flex-direction: column;
    height: auto;
    gap: 10.4rem;
  }

  .MainWrapper_content_2 {
    gap: 5rem;
    align-items: flex-start;
  }

  .MainWrapper_content_links {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .email_footer{
    margin-left: 12rem;
  }

  .MainWrapper {
    padding: 2.8rem 2rem 0 2rem;
  }
}


@media screen and (max-width: 435px) {

  .banner_wrapper{
    padding-top: 1rem;
  }
  .MainWrapper_content {
    width: 100%;
  }

  .Input_container {
    width: 100%;
  }

  .MainWrapper_content h1 {
    width: auto;
  }
}

