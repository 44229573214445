.ContainerDiv {
  font-family: "Mulish";
  font-style: normal;
}

/* .Main_Section{
  padding: 0 5rem;
  background-color: red;
} */

.BannerImage1,
.BannerImage2,
.BannerImage3 {
  height: 75vh;
  object-fit: cover;
  object-position: top center;
}

.CenteredDiv {
  margin-top: 5rem;
  text-align: center;
  color: #000000;
}

.Blog_Text {
  padding: 0 7rem;
  font-size: 1.5rem;
  width: 70%;
  margin: auto;
}

.CenteredTextDiv {
  display: flex;
  position: relative;
  /* margin-bottom: 3.5rem; */
  margin-bottom: 3rem;
}

.CenteredLine1 {
  border: 1px solid #c4c4c4;
  width: 32%;
  margin-top: 2rem;
}

.CenteredLine2 {
  border: 1px solid #c4c4c4;
  width: 32%;
  margin-left: auto;
  margin-top: 2rem;
}

.CenteredText {
  font-weight: 700;
  line-height: 2.4rem;
  font-size: 4rem;
  position: absolute;
  top: 0;
  left: 50;
  right: 50;
  width: 100%;
}

.WordingDiv {
  width: 100%;
  display: flex;
}

.CenteredDivWords {
  color: rgba(0, 0, 0, 0.9);
  line-height: 35px;
  font-size: 2.2rem;
  width: 98.5rem;
  margin: auto;
}

.Container_Tab {
  margin-top: 4.8rem;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 6rem;
}

.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
  padding: 0 4rem;
}

.Skeleton_padding {
  padding: 0 5rem;
}

.contents::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.3);
}
.contents::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.3);
  border-radius: 5px;
}
.contents::-webkit-scrollbar {
  background: #f1f1f1;
}
.contents::-webkit-scrollbar {
  width: 0px;
}

.ContentArrivals {
  margin-top: 10rem;
  /* display: flex;
    flex-wrap: wrap;
    align-items: flex-start; */
  /* justify-content: space-between; */

  grid-gap: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.card {
  /* width: 31%; */
  background: rgba(196, 196, 196, 0.25);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  text-align: center;
  margin-bottom: 50px;
  position: relative;
  cursor: pointer;
}

.card:hover {
  background-color: grey;
}

.ArrivalDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  padding: 3.5rem 2rem;
}

.ArrivalText {
  font-weight: 700;
  font-size: 20px;
  /* line-height: 14px; */
  text-align: center;
  letter-spacing: 0.2px;
  color: #000000;
  text-transform: uppercase;
}

.BlogContent {
  margin-top: 8rem;
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
}

.contentAnimation {
  margin-top: 10rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .cardMain{
    width: 31%;
    text-align: center;
    position: relative;
    cursor: pointer;
    border: none !important;
    background-color: transparent;
  } */

.cardMain {
  /* width: 31%; */
  text-align: center;
  position: relative;
  cursor: pointer;
  border: none !important;
  background-color: transparent;
}

.responsiveTab {
  margin-left: 13.2rem;
}

.CardBody {
  padding: 0 6rem;
  background-color: transparent !important;
}

.CardText {
  font-size: 2.2rem;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #000000;
  margin-top: 3.6rem;
  margin-bottom: 4.5rem;
}

.CardTexts {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.AnimationButton {
  margin-top: 2.4rem;
  outline: none;
  background-color: transparent;
  font-size: 2rem;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #000000;
}

.rule {
  width: 15rem;
  height: 2px;
}

.LandingBase {
  margin-top: 4rem;
  background-color: rgba(224, 224, 224, 1);
  border-bottom: 1px solid lightgray;
  padding: 0 1rem;
}

.CardsDisplay {
  padding: 5rem 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  gap: 3rem;
  margin-bottom: 5rem;
  /* display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));  */
}

.CardsDisplay div {
  width: 30rem;
}

.CardsDisplay p:not(:last-child) {
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.4rem;
  text-align: center;
  margin: 3rem 0;
  text-transform: uppercase;
}

.CardsDisplay p:last-child {
  font-weight: 400;
  font-size: 2rem;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 9.5rem;
}

.NotFound_Container {
  text-align: center;
  margin-top: 3rem;
}

@media screen and (min-width: 1450px) {
  .CenteredDiv,
  .CenteredTextDiv,
  .ContentArrivals,
  .contents,
  .Blog_Text {
    width: 1450px;
    margin: auto;
  }

  .CardsDisplay{
  padding: 5rem 0;
  }

  .contents{
    padding:0;
  }

  .BlogContent {
    margin-bottom: 2rem;
  }

  .ContentArrivals {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 990px) {
  .NotFound_Container {
    margin-top: 10rem;
    padding: 0 1rem;
  }

  .BannerImage3 {
    height: 70vh;
    object-position: right center;
  }

  .BannerImage2 {
    height: 70vh;
    object-position: left center;
  }

  .BannerImage1 {
    height: 70vh;
    object-position: left center;
  }
}

@media screen and (max-width: 850px) {
  .CardsDisplay {
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .contents {
    padding: 0;
    /* gap: 15px; */
  }
  .Skeleton_padding {
    padding: 0;
  }

  .Blog_Text {
    padding: 0 3rem;
    font-size: 1.5rem;
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .responsiveTab {
    margin-left: 5.2rem;
  }

  .CenteredLine1 {
    display: none;
  }

  .CenteredLine2 {
    display: none;
  }

  .CenteredText {
    line-height: 4.4rem;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
  }
}

@media screen and (max-width: 470px) {
  .CardsDisplay {
    padding: 5rem;
  }
}

@media screen and (max-width: 370px) {
  .Container_Tab {
    font-size: 1.7rem;
  }
}


